import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  public analyticsOverview: Object[] = [
    {
      metric: "New User Signups",
      isMeasured: false,
      range: "Last 30 Days",
      change: 0,
      value: 0
    },
    {
      metric: "Active Users",
      isMeasured: false,
      range: "Last 30 Days",
      change: 0,
      value: 0
    },
    {
      metric: "Active Organizations",
      isMeasured: false,
      range: "Last 30 Days",
      change: 0,
      value: 0
    }
  ];

  public analyticsSub: BehaviorSubject<Object[]> = new BehaviorSubject(this.analyticsOverview);
  
  constructor() { }

  public subToAnalytics(): Observable<Object[]> {
    return this.analyticsSub.asObservable()
  }

  public getJSDay(day: number){
    let days: any = {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday"
    }
    return days[day];
  }

  public getJSHour(hour: number){
    if(hour == 0){ return "12:00AM"}
    else if(hour > 0 && hour < 10){
      return "0"+hour+":00AM";
    }else if(hour >= 10 && hour < 12){
      return hour+":00AM";
    }else if(hour == 12){
      return hour+":00PM"
    }else if(hour > 12){
      let h = hour - 12;
      if(h < 10){
        return "0"+h+":00PM";
      }else{
        return h+":00PM";
      }
    }
  }
}
